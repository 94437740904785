import { Component, Input } from '@angular/core';

import { environment } from 'environments/environment';

import { AlertService } from '@core/helpers';
import { BrazeService } from '@core/helpers';
import { SubscriptionService } from '@core/services';
import { Subscriber } from '@core/types';

@Component({
  selector: 'apd-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent {
  @Input() incentivesHeader: string;
  @Input() incentivesSubHeader: string;
  @Input() subscriptionHeader: string;
  @Input() formOnly = false;
  @Input() submitText: string;
  @Input() formLocation: string;

  subscriber: Subscriber = {
    email: '',
    tags: ['Newsletter Sign-up', 'New Site'],
  };
  cdnBasePath = environment.cdnBasePath;

  /**
   * Constructor
   * @param subscriptionService SubscriptionService
   */
  constructor(
    private subscriptionService: SubscriptionService,
    private alertService: AlertService,
    private brazeService: BrazeService
  ) {}

  subscribe(event: Event): void {
    event.preventDefault();
    this.brazeService.userSubscribed(this.subscriber.email);
    this.subscriptionService.subscribeUser(this.subscriber).subscribe(
      () => {
        this.alertService.generic('You have been subscribed.', 'success');
      },
      error => {
        if (error.error_code === 422) {
          this.alertService.generic('Email address already subscribed');
        } else {
          this.alertService.generic(
            'Error while subscribing. Please try again later'
          );
        }
      }
    );
  }
}
