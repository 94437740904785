import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { ApiService } from '@core/services';
import { Development, Enquirer } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class BrazeService {
  isBrowser = false;
  window: Window;
  braze;

  pathMap = {
    '/': 'Home',
    search: 'Search',
    showcase: 'Showcase',
    suburb: 'Suburb Profile',
    industry: 'Industry Profile',
  };

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.initialize();
  }

  initialize(): void {
    console.log('----BRAZE PRE INITIALIZE------');
    if (this.isBrowser) {
      console.log('----BRAZE INITIALIZE------');
      import('@braze/web-sdk').then(braze => {
        this.braze = braze;
        this.braze.initialize(environment.brazeApiKey, {
          baseUrl: environment.brazeEndpoint,
          enableLogging: true,
          allowUserSuppliedJavascript: true,
        });
        this.braze.openSession();

        console.log('----BRAZE POST INITIALIZE------');
        console.log(this.braze);
      });
    }
  }

  userEnquired(enquirer: Enquirer): void {
    console.log('BRAZE USER ENQUIRED');
    const data = {
      attributes: [
        {
          external_id: enquirer.email,
          email: enquirer?.email,
          phone: enquirer?.mobile,
          post_code: enquirer?.postCode,
        },
      ],
    };

    const pathDetails = this.getPathDetails();

    const enquiryData = {
      ...pathDetails,
      enquiry_audience: enquirer?.audience,
      enquiry_contact_method: enquirer?.contactMethod,
      enquiry_looking_to_buy: enquirer?.lookingToBuy,
      enquiry_message: enquirer?.message,
      enquiry_name: enquirer?.name,
      enquiry_post_code: enquirer?.postCode,
      enquiry_price_range: enquirer?.priceRange,
      enquiry_select_pre_approval: enquirer?.selectPreApproval,
    };
    console.log(this.braze.getDeviceId());
    this.braze.changeUser(enquirer.email);
    this.trackUser(data);
    this.braze.logCustomEvent('userEnquired', enquiryData);
  }

  userSubscribed(email: string): void {
    console.log('BRAZE USER subscribed');
    console.log(this.braze.getDeviceId());

    this.braze.changeUser(email);
    this.braze.logCustomEvent('userSubscribed');
  }

  logEvent(eventName: string, development?: Development): void {
    console.log(this.braze.getDeviceId());
    const pathDetails = this.getPathDetails();
    const attributes = {
      elementUrl: pathDetails?.url,
      state: pathDetails?.state,
      suburb: pathDetails?.suburb,
      pageName: pathDetails?.pageName,
      developmentName: development?.title,
      developmentStatus: development?.status,
      developmentPrice: development?.priceSearch,
      developmentConfigBed: development?.bedrooms,
    };
    console.log('BRAZE logevent');
    console.log(attributes);
    this.braze.logCustomEvent(eventName, attributes);
  }

  logPage(): void {
    console.log('BRAZE logPage');
    this.braze.logCustomEvent('pageView');
  }

  trackUser(body): void {
    console.log('----BRAZE TRACK USER ----');
    console.log(body);

    this.apiService
      .post('/braze/users/track', body)
      .pipe(map(response => response))
      .subscribe(res => {
        return res;
      });
  }

  getPathDetails() {
    if (this.isBrowser) {
      let pageName = '';

      this.window = this.document.defaultView;

      const pathName = this.window.location.pathname;
      let pathNameArr = pathName.split('/'),
        state = '',
        suburb = '';

      pathNameArr = [...new Set(pathNameArr)];
      pathNameArr = pathNameArr.filter(
        (x): x is string => x !== null && x != ''
      );

      const url = this.window.location.href;

      if (pathName == '/' && pathNameArr.length == 0) {
        pageName = this.pathMap[pathName];
      } else {
        if (pathNameArr.length == 3) {
          state = pathNameArr[1];
          suburb = pathNameArr[2];
          if (pathNameArr[0] == 'new-developments-investment-property') {
            pageName = this.pathMap['suburb'];
          } else {
            pageName = this.pathMap['search'];
          }
        } else if (pathNameArr.length == 2) {
          if (pathNameArr[0] == 'industry-profile-details') {
            pageName = this.pathMap['industry'];
          }
        } else {
          pageName = this.pathMap['showcase'];
          state = pathNameArr[1];
          suburb = pathNameArr[2];
        }
      }

      return {
        url: url,
        pageName: pageName,
        state: state,
        suburb: suburb,
      };
    }

    return null;
  }
}
